<template>
  <div class="contentLog">
    <!-- 登录表单 -->
    <ml-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules">
      <!-- 输入框-账号 -->
      <ml-input
        prop="userCode"
        prefixIcon="el-icon-user"
        placeholder="请输入账号"
        v-model="loginForm.userCode"
      />
      <!-- 输入框-密码 -->
      <ml-input
        prop="userPass"
        prefixIcon="el-icon-unlock"
        placeholder="请输入密码"
        v-model="loginForm.userPass"
        showPassword
      />
      <el-form-item prop="valiCode" :required="true">
        <div class="form-code-box">
          <el-input v-model="loginForm.valiCode" prefixIcon="el-icon-wallet"></el-input>
          <img :src="codeImg" alt="" srcset="" class="codeImg" @click="getVeryCodeHandle" />
        </div>
      </el-form-item>
      <!-- 登陆按钮 -->
      <ml-button :showCancel="false" submitText="登录" @click-submit="clickLoginBtn" />
    </ml-form>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { onMounted, reactive, ref } from 'vue'
import { VRRYCODE } from '@/API/index'
export default {
  name: 'ContentLogin',
  emits: ['click-login-btn'],
  setup(props, context) {
    const loginFormRef = ref()
    const { dispatch } = useStore()
    // 登陆数据配置
    const loginForm = reactive({
      userCode: '',
      userPass: '',
      valiCode: ''
    })
    let codeImg = ref('')
    // 表单校验配置
    const loginFormRules = {
      userCode: [{ required: true, message: '请输入账号', trigger: 'blur' }],
      userPass: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      valiCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
    }
    // 点击登录按钮，发布订阅事件
    const clickLoginBtn = () => {
      loginFormRef.value.CustomFormRef.validate()
        .then(() => {
          context.emit('click-login-btn', loginForm)
        })
        .catch(() => {})
    }
    const getVeryCodeHandle = () => {
      codeImg.value = VRRYCODE + '?time=' + Date.now()
    }
    onMounted(() => {
      getVeryCodeHandle()
    })
    return {
      loginFormRef,
      loginForm,
      loginFormRules,
      clickLoginBtn,
      getVeryCodeHandle,
      codeImg
    }
  }
}
</script>

<style lang="scss">
.contentLog {
  display: flex;
  justify-content: center;
  .el-input__inner {
    height: 47px;
    width: 293px;
  }
  .el-button {
    margin-top: 20px;
    width: 293px;
    font-size: 20px;
    height: 47px;
  }
  .el-input__inner {
    border-radius: 5px;
  }
  .el-input__icon {
    line-height: 49px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 50px;
  }
  .el-input__prefix {
    font-size: 20px;
    padding-left: 10px;
  }
  .form-code-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .codeImg {
    width: 110px;
    height: 47px;
    border-radius: 4px;
    cursor: pointer;
  }
}
.contentLog .form-code-box .el-input__inner {
  width: 170px;
}
</style>
